import _lodashGet from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import root from 'window-or-global';

import { Language } from '../types/lang';
import { NewslettersExist } from '../types/newsletter';

import { get, set } from './storage';

export const newslettersByCountries: Record<CategoryLang, NewsletterCategory[]> = {
  pl: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  en: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  es: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  de: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  ua: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  lt: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  ru: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  ro: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper'],
  hu: ['driver', 'logistician', 'carrier', 'forwarder', 'shipper']
} as const;

export const agreementsByCountries: Record<CategoryLang, NewsletterConsent[]> = {
  pl: ['marketing', 'commercialInside', 'commercialOutside'],
  de: ['marketing', 'commercialInside', 'commercialOutside'],
  en: ['marketing', 'commercialInside', 'commercialOutside'],
  es: ['marketing', 'commercialInside', 'commercialOutside'],
  lt: ['marketing', 'commercialInside', 'commercialOutside'],
  hu: ['marketing', 'commercialInside', 'commercialOutside'],
  ro: ['marketing', 'commercialInside', 'commercialOutside'],
  ru: ['marketing', 'commercialInside', 'commercialOutside'],
  ua: ['marketing', 'commercialInside', 'commercialOutside'],
} as const;

export const showNewsletter = (lang: Language): boolean => Object.keys(NewslettersExist).includes(lang);

export const showNewsletterByCategory = (lang: Language, category): boolean =>
  showNewsletter(lang) && newslettersByCountries[lang].includes(category);

export type CategoryLang = 'pl' | 'de' | 'en' | 'es' | 'lt' | 'ru' | 'ua' | 'hu' | 'ro';

export const newsletterCategories = [
  'driver',
  'logistician',
  'carrier',
  'forwarder',
  'shipper'
] as const;

export type NewsletterCategory = (typeof newsletterCategories)[number];

export type NewsletterConsent = 'marketing' | 'commercialInside' | 'commercialOutside';

export const updatePopupCounter = (count: number | undefined = 1) => {
  // logger.warn('NEWSLETTER COUNTER %o', count);
  const cookie = isEmpty(get('newsletter')) ? {} : get('newsletter');
  if (getPopupCounter() >= 2) {
    setNewsletterDate(60);
  } else {
    // logger.warn('NEWSLETTER COUNTER SET');
    set('newsletter', {
      ...cookie,
      counter: getPopupCounter() + count,
    });
  }
};

export const setNewsletterDate = (nextDate = 1) => {
  const lastDate = getNewsletterDate();
  const newDate = calcNextDate(nextDate);
  //@ts-ignore
  const cookie = _lodashGet(get('newsletter'), {});
  if (newDate > lastDate) {
    set('newsletter', {
      ...cookie,
      counter: 0,
      nextViewDate: newDate,
    });
  }
};

export const calcNextDate = (nextDate = 1) => {
  const dayInSecond = 86400;
  return +new Date() + nextDate * dayInSecond * 1000;
};

export const getNewsletterDate = () => {
  return _lodashGet(get('newsletter'), 'nextViewDate', 0);
};

export const getPopupCounter = () => {
  return _lodashGet(get('newsletter'), 'counter', 0);
};

export const showNewsletterPopup = () => {
  // const dateFromCookie = getNewsletterDate();
  // // logger.warn('SHOW NEWSLETTER POPUP %o', dateFromCookie, getPopupCounter());
  // return dateFromCookie === null || (dateFromCookie < +new Date() && getPopupCounter() <= 4);
  return get('newsletterSubscribe', root.sessionStorage) === 'true';
};

export const setSessionNewsletter = () => {
  set('newsletterPopup', 'false', root.sessionStorage);
};
