'use client';
import cn from 'classnames';
import { useLocale } from 'next-intl';
import { useMemo, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosGlobe } from 'react-icons/io';
import { useClickAway } from 'react-use';

import { Link } from '@lib/navigation';
import { languageNames } from 'types/lang';

interface LanguageSelectorProps {
  className?: string;
  compact?: boolean;
  isMobile?: boolean;
  direction?: 'up' | 'down';
}

const inactiveLangs = ['hu', 'ro', 'ru'];

export default function LanguageSelector({
  className,
  compact,
  isMobile,
  direction = 'down',
}: LanguageSelectorProps) {
  const lang = useLocale();
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const languages = useMemo(() => {
    return Object.keys(languageNames)
      .filter((language) => language !== lang)
      .filter((language) => language !== 'es') // TODO Remove after enabling es
      .sort((a, b) => {
        if (inactiveLangs.includes(a) && !inactiveLangs.includes(b)) return 1;
        if (inactiveLangs.includes(b) && !inactiveLangs.includes(a)) return -1;

        return 0;
      });
  }, [lang]);

  useClickAway(menuRef, () => setOpen(false));
  // border radius round on left and right, top and bottom flat for mobile
  const buttonBorder = isMobile ? 'border rounded-full py-2 px-3 border-white' : '';
  return (
    <div className={cn('relative', className)} ref={menuRef}>
      <button
        className={cn('flex items-center gap-1 dark:text-white',
          buttonBorder,
          {
            'text-white': isMobile,
            'text-black bg-white text-xs dark:bg-premium-darker': !isMobile,
          })}
        onClick={() => setOpen((state) => !state)}
      >
        {!compact &&
          <IoIosGlobe className="h-6 w-6" />
        }
        {compact ? lang.toUpperCase() : languageNames[lang]}
        <IoIosArrowDown className="h-3 w-3" />
      </button>
      <div
        className={cn(
          'absolute left-0 rounded-lg border border-gray-600  dark:bg-premium-dark dark:text-white transition-opacity z-[100]',
          {
            'pointer-events-none opacity-0': !open,
            'opacity-100': open,
            'top-full mt-3': direction === 'down',
            'bottom-full mb-3': direction === 'up',
            'text-white bg-premium-dark': isMobile,
            'text-black bg-white': !isMobile,
          },
        )}
      >
        {languages.map((language, index) => (
          <Link
            href="/"
            locale={language as any}
            key={language}
            className={cn(
              'block w-full p-2 text-left text-xs transition-colors hover:bg-gray-200 hover:text-black',
              {
                'rounded-t-lg': index === 0,
                'rounded-b-lg': index === languages.length - 1,
              },
            )}
          >
            {languageNames[language]}
          </Link>
        ))}
      </div>
    </div>
  );
}
