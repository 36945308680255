// export type langTypes = 'pl' |'en' |'de' |'hu' |'lt'  |'ro' |'ru'

export const langs = {
  pl: 'pl',
  en: 'en',
  es: 'es',
  de: 'de',
  hu: 'hu',
  lt: 'lt',
  ro: 'ro',
  ru: 'ru',
  ua: 'ua',
  uk: 'uk',
} as const;

export const languageNames = {
  pl: 'Polski',
  en: 'English',
  es: 'Español',
  de: 'Deutsch',
  hu: 'Magyar',
  lt: 'Lietuvių',
  ro: 'Română',
  ru: 'Русский',
  ua: 'Українська',
};

export type langDropdownTypes = keyof typeof languageNames;

export const languageNameCodes = Object.keys(languageNames) as langDropdownTypes[];
export type Language = typeof langs[keyof typeof langs];

export enum flag {
  pl = 'pl',
  en = 'gb',
  es = 'es',
  de = 'de',
  hu = 'hu',
  lt = 'lt',
  ro = 'ro',
  ru = 'ru',
  ua = 'ua',
}

export enum locales {
  pl = 'pl_PL',
  en = 'en_GB',
  es = 'es_ES',
  de = 'de_DE',
  hu = 'hu_HU',
  lt = 'lt_LT',
  ro = 'ro_RO',
  ru = 'ru_RU',
  ua = 'uk_UA',
}

export type localeTypes = typeof locales[keyof typeof locales];

const localeList = ['en_GB', 'pl_PL', 'es_ES', 'de_DE', 'hu_HU', 'lt_LT', 'ro_RO', 'ru_RU', 'uk_UA'] as const
const localeListLowercase = ['en_gb', 'pl_pl', 'es_es', 'de_de', 'hu_hu', 'lt_lt', 'ro_ro', 'ru_ru', 'uk_ua'] as const
export type localeListTypes = typeof localeList[number];
export type localeListLowercaseTypes = typeof localeListLowercase[number];
